import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';

type modalContext = {
  selectedModalId: string;
  setSelectedModalId: Dispatch<SetStateAction<string>>;
  prevFocusedElementRef: MutableRefObject<HTMLButtonElement | null> | null;
  isGenericModalOpen: boolean;
  setIsGenericModalOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalIDContext = createContext<modalContext>({
  selectedModalId: '',
  setSelectedModalId: () => '',
  prevFocusedElementRef: null,
  isGenericModalOpen: false,
  setIsGenericModalOpen: () => false,
});

export const useModalIdContext = () => useContext(ModalIDContext);

export const ModalIdContextProvider = ({ children }: { children: ReactNode }) => {
  const prevFocusedElementRef = useRef(null);
  const [selectedModalId, setSelectedModalId] = useState<string>('');
  const [isGenericModalOpen, setIsGenericModalOpen] = useState<boolean>(false);

  return (
    <ModalIDContext.Provider
      value={{
        selectedModalId,
        setSelectedModalId,
        prevFocusedElementRef,
        isGenericModalOpen,
        setIsGenericModalOpen,
      }}
    >
      {children}
    </ModalIDContext.Provider>
  );
};
